<template>
  <!-- v-click-outside="onClickOutside"  -->
  <vue-perfect-scrollbar :settings="{ suppressScrollX: true, wheelPropagation: false }" class="h-100 rtl-ps-none ps scroll" style="height: 100%">
    <div class="pa-5 relative">
      <v-app-bar :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'" :dark="$vuetify.theme.dark" class="" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            <!-- [{{ patientData && patientData.name }}]{{ $t("patient.title") }} -->
            [{{ clinicData && clinicData.hospitalName }}] 상품정보 관리
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <v-tabs class="thisTabs" v-model="tabIdx" align-tabs="center" bg-color="deep-purple-accent-4" stacked @change="tabClick">
      <v-tab> 상품정보관리 </v-tab>
      <v-tab> 상품타입관리 </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabIdx">
      <Product ref="refProduct" :clinicData="clinicData" />
      <ProductTypeItem ref="refProductTypeItem" :clinicData="clinicData" />
    </v-tabs-items>

    <!-- 편집창 -->
    <!-- <DialogPatientAdd
      ref="refPatientAdd"
      @complete="userEditorCompleteSaveData"
    /> -->
  </vue-perfect-scrollbar>
</template>
<script>
import { mapActions } from "vuex";
import Product from "./product/Product.vue";
import ProductTypeItem from "./productTypeItem/ProductTypeItem.vue";
// import ControlUI from "./controlUI/ControlUI.vue";
// import CareOverview from "./careOverview/CareOverview.vue";
// import MedicalInformation from "./medicalInformation/MedicalInformation.vue";
// import PreventiveCare from "./preventiveCare/PreventiveCare.vue";
// import Assessment from "./assessment/Assessment.vue";
// import ActionCarePlan from "./actionCarePlan/ActionCarePlan.vue";
// import CarePlanHistory from "./carePlanHistory/CarePlanHistory.vue";
// import DialogPatientAdd from "@/components/commonV2/Dialog/DialogPatientAdd.vue";
// import DialogCareNoteForTimer from "@/components/commonV2/Dialog/DialogCareNoteForTimer.vue";
// import DialogInputFid from "@/components/commonV2/Dialog/DialogInputFid.vue";

export default {
  components: {
    Product,
    ProductTypeItem,
    // CareNoteForPageDrawer: () => import("./careNote/CareNoteForPage.vue"),
    // SurveyPageDrawer: () => import("./survey/SurveyPage.vue"),
    // ControlUI,
    // CareOverview,
    // MedicalInformation,
    // PreventiveCare,
    // Assessment,
    // ActionCarePlan,
    // CarePlanHistory,
    // DialogPatientAdd,
    // DialogCareNoteForTimer,
    // DialogInputFid,
  },
  data() {
    return {
      tabIdx: 0,
      clinicData: null,
      // CareNoteForPageDrawer: false,
      // SurveyPageDrawer: false,
      // QuestionnaireDrawer: false,
    };
  },
  watch: {},
  methods: {
    _updateData(_clinicData) {
      console.log("tabs _clinicData", _clinicData);
      this.clinicData = _clinicData;
      this.tabClick(this.tabIdx);
    },
    tabClick(e) {
      console.log("e", e);
      this.tabIdx = e;
      setTimeout(() => {
        if (e == 0) this.$refs.refProduct._infoUpdate();
        if (e == 1) this.$refs.refProductTypeItem._infoUpdate();
        // if (e == 2) this.$refs.refPreventiveCare._infoUpdate();
        // if (e == 3) this.$refs.refAssessment._infoUpdate();
        // if (e == 4) this.$refs.refActionCarePlan._infoUpdate(this.patientData);
      }, 100);
    },
    // drawer가 닫힐때 처리
    // onClose() {
    //   // this.openCareNodeForTimer();
    // },
    // 외부 클릭
    // onClickOutside(e) {
    //   // console.log('onClickOutside',e);
    //   // e.stopPropagation();
    // },
    // openCareNoteForPage() {
    //   console.log("openCareNoteForPage");
    //   this.CareNoteForPageDrawer = !this.CareNoteForPageDrawer;
    //   setTimeout(() => {
    //     this.$refs.refCareNoteForPage._showDrawer(this.patientData);
    //   }, 300);
    // },
    // openActivity() {
    //   this.$refs.refActivity._showDrawer(this.patientData);
    // },
    // addSurvey() {
    //   console.log("openSurveyPageDrawer");
    //   this.SurveyPageDrawer = !this.SurveyPageDrawer;
    //   setTimeout(() => {
    //     this.$refs.refSurvey._showDrawer(this.patientData);
    //   }, 300);
    // },
    // fedRequest(option) {
    //   setTimeout(
    //     () => this.$refs.refDialogInputFid.open(this.patientData, option),
    //     300
    //   );
    // },
    // openCareNodeForTimer() {
    //   // this.$refs.refControlUI._pauseTimer();
    //   this.$refs.refCareNoteForTimer._showModal(this.patientData);
    // },
    // careNoteClickCancel() {
    //   // 타이머 stop --> 완전히 종료필요
    //   // console.log('tab page visible', this.visible);
    //   if (!this.SurveyPageDrawer) {
    //     this.setTimer("resume");
    //   } else {
    //     this.setTimer("stop");
    //   }
    // },
    // careNoteSaveComplete() {
    //   this.$emit("careNoteSaveComplete");
    //   this.setTimer("stop");
    // },
    // withoutSaveClose() {
    //   this.$emit("withoutSaveClose");
    //   this.setTimer("stop");
    // },
    // openUserInfoEdit() {
    //   this.$refs.refPatientAdd.Open(
    //     this.patientData,
    //     this.patientData.hospital_id
    //   );
    // },
    // userEditorCompleteSaveData() {
    //   this.$emit("completeSaveData");
    // },
    ...mapActions(["setTimer"]),
  },
};
</script>
<style lang="scss">
header.grey.lighten-5.theme--light.v-app-bar.v-app-bar--fixed.v-sheet.v-toolbar.v-toolbar--flat {
  top: 0;
}

.thisTabs {
  margin-top: 40px !important;
}

// .mainStyle {
//   padding: 30px 0px 0px 10px !important;
// }</style>